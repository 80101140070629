@import "~@angular/material/prebuilt-themes/indigo-pink.css";
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:host ::ng-deep text.f0 {font-family:cmmi9;font-size:8.966376px}
:host ::ng-deep text.f1 {font-family:cmr9;font-size:8.966376px}
:host ::ng-deep text.f2 {font-family:cmsy7;font-size:6.973848px}
:host ::ng-deep text.f3 {font-family:cmmi10;font-size:9.96264px}
:host ::ng-deep text.f4 {font-family:cmmi7;font-size:6.973848px}
:host ::ng-deep text.f5 {font-family:cmr10;font-size:9.96264px}


.columns {
    display: flex;
    flex-direction: column;
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}


.width100 {
    width: 100%;
}


.width50 {
    width: 50%;
}



a{
    text-decoration: none;
}

svg rect { stroke:red; fill:transparent }


.main app-show-slide{
    width: 100%;
}


.explain {
    padding: 10px;
    margin:10px;
    box-shadow: 3px 5px 10px #888888;
}

.margin-20{
    margin:20px;
}

.container{
    margin: 20px;
}


.txt-success{ color:#5cb85c}
.txt-grey{ color:#b3b3b3}
.txt-danger{ color:#dc3545}

.alert-success{ 
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-warning{     
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.alert-danger{ 
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}